<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="form" :inline="true" :model="formInline" small="size">
      <!-- <el-row>
        <el-col :span="6"> -->
      <el-form-item label="医生姓名:">
        <el-input
          v-model.trim="formInline.doctorName"
          v-check-permission
          placeholder="请输入医生姓名"
        />
      </el-form-item>
      <!-- </el-col>
        <el-col :span="10"> -->
      <el-form-item label="职称:">
        <el-select v-model="formInline.occupationCode" filterable>
          <el-option
            v-for="item in allOccupationList"
            :key="item.occupationCode"
            :value="item.occupationCode"
            :label="item.occupationName"
          />
        </el-select>
        <el-select
          v-if="formInline.occupationCode !== ''"
          v-model="formInline.professionalTitleCode"
          placeholder=""
          filterable
        >
          <el-option
            v-for="item in professionalList"
            :key="item.professionalTitleCode"
            :label="item.professionalTitleName"
            :value="item.professionalTitleCode"
          />
        </el-select>
      </el-form-item>
      <!-- </el-col>
        <el-col :span="8"> -->
      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="queryClear()"> 重置 </el-button>
      </el-form-item>
      <!-- </el-col>
      </el-row> -->
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="teamName" label="医生团队名称" align="center" />
      <el-table-column prop="deptName" label="科室" align="center" />
      <el-table-column prop="doctorName" label="医生姓名" align="center" />
      <el-table-column
        prop="professionalTitleName"
        label="医生职称"
        align="center"
      />
      <el-table-column prop="openConsultationName" label="问诊类型" width="150">
        <template slot-scope="scope">
          <span v-if="!scope.row.openConsultationName" />
          <span
            v-for="(item, index) in scope.row.openConsultationName.split(',')"
            v-else
            :key="index"
            >{{ item }}<br
          /></span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 0)"
          >
            查看
          </el-button>
          <el-button
            v-if="$checkPermission('EDITOR', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row, 1)"
          >
            编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'ServiceManageList',
  data() {
    return {
      allOccupationList: [], // 加上全部
      loading: false,
      formInline: {
        doctorName: '',
        occupationCode: '',
        professionalTitleCode: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {
    ...mapState('basicDictionary', {
      occupationList: (state) => state.occupationList,
      professionalList: (state) => state.professionalList,
    }),
  },
  watch: {
    'formInline.occupationCode': function (value) {
      this.formInline.professionalTitleCode = '';
      this.allOccupationList.forEach((item) => {
        if (item.occupationCode === value && value) {
          this.getprofessionalList(item.occupationCode);
        }
      });
    },
  },
  mounted() {
    this.getConsultationList();
    // this.getprofessionalList();
    this.getAllOccupationList();
  },
  methods: {
    ...mapActions('basicDictionary', [
      'occupationQueryList',
      'professionalQueryList',
    ]),
    ...mapActions('doctorManage', [
      'consultationPageQuery',
      'consultationGet',
      'consultationEdit',
    ]),
    // 职业列表
    async getAllOccupationList() {
      await this.occupationQueryList({});
      const allDic = JSON.parse(JSON.stringify(this.occupationList));
      allDic.unshift({
        occupationCode: '',
        occupationName: '全部',
      });
      this.allOccupationList = allDic;
    },
    // 职称列表
    getprofessionalList(index) {
      this.professionalQueryList({ occupationCode: index });
    },
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getConsultationList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getConsultationList();
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        doctorName: '',
        occupationCode: '',
        professionalTitleCode: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getConsultationList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getConsultationList();
    },
    // 分页查询问诊类型列表
    getConsultationList() {
      this.loading = true;
      const params = {
        ...JSON.parse(JSON.stringify(this.formInline)),
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      // if(this.formInline.occupationCode === '') {
      //   params.occupationCode = '';
      // }
      this.consultationPageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item, type) {
      this.$router.push({
        path: '/doctorManage/consultationDetail',
        query: {
          id: item.id,
          type: type,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
